/* @media (max-width: 767px) { */

/* para borrar  */

/* para borrar */

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition: opacity 0.9s ease;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.black-fade-transition {
  transition: opacity 0.3s, background-color 0.3s;
  background-color: black;
}

.textoTerrir {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  margin-left: 20px;
  line-height: 25px;
}

.logo {
  width: 240px;
  margin-bottom: -60px;
  z-index: 0;
}

.logoTerroir {
  width: 240px;
  margin-top: 250px;
  z-index: 0;
  padding-bottom: 200px;
}
.image {
  width: 100%;
  height: auto;
}

body {
  font-weight: 400;
  /* background-color: black; */
}

.containerHome {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  -webkit-overflow-scrolling: touch;
  /* height: 100vh; */
  z-index: 1;
  overflow-y: scroll;
}
.background {
  background-image: url("./images/background.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.contenido-dinamico {
  display: none;
}

.contenido-dinamico-contact {
  display: none;
}

@media screen and (min-width: 768px) {
  .contenido-dinamico {
    display: block;
  }
  .contenido-dinamico-contact {
    display: flex;
  }
}

.backgroundContacto {
  background-image: url("./images/background-contact-mob.jpg");
  background-size: cover;
  background-position: center;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .backgroundContacto {
    background-image: none;
    background-color: black;
  }
}

.backgroundHambuerguesa {
  background-image: url("./images/fondoHambuerguesa.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.backgroundTerroir {
  background-image: url("./images/fondoTerroir.png");
  background-size: cover;
  background-position: center;
}

.menu {
  color: #f3eee3;
  font-size: 2rem;
  cursor: pointer;
}

.menu:hover {
  color: #c4b27d;
}

.menu-desk {
  font-size: 1.8rem;
  cursor: default;
}

.menu-desk-hambur {
  color: #c4b27d;
  font-size: 1.8rem;
  cursor: default;
}

@media screen and (min-width: 768px) {
  .menu-desk {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .menu-desk {
    font-size: 1.9rem;
  }

  .menu-desk-hambur {
    font-size: 2.2rem;
    cursor: default;
  }
}

.subMenu {
  text-decoration: none;
  font-size: 0.8rem;
  letter-spacing: 1.5px;
  color: #f3eee3;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .subMenu {
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 1024px) {
  .subMenu {
    font-size: 0.8rem;
  }
}
.subMenu2 {
  font-size: 13px;
  letter-spacing: 1.5px;
  color: #fff;
  margin-bottom: 20px;
  font-family: Conv_maisonneue-book;
}

.subMenu:hover {
  color: #cab57e; /* Color al pasar el cursor por encima del texto */
  transition: all;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.footerItems {
  color: #fff;
  outline: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: normal;
  color: #fff;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.awssld__bullets {
  margin-bottom: 50px;
}

.awssld__bullets button {
  padding: 0;
  display: block;
  width: 6px !important;
  height: 6px !important;
  margin: 25px !important;
  border-radius: 50%;
  --control-bullet-color: #cab57e;
  background: var(--control-bullet-color);
  text-indent: -9999px;
  overflow: hidden;
  cursor: pointer;
  border: none;
  transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45),
    background-color 0.175s ease-out;
}

.awssld__bullets .awssld__bullets--active {
  transform: scale(2.5) !important;

  --control-bullet-active-color: #cab57e;
  background: var(--control-bullet-active-color);
}

.awssld__controls__arrow-right {
  opacity: 0 !important;
}
.awssld__controls__arrow-left {
  opacity: 0 !important;
}

.awssld__next {
  width: 80px !important;
  margin-right: 200px !important; /* Adjust the right margin as needed */
}

.awssld__prev {
  width: 80px !important;
  margin-left: 200px !important; /* Adjust the left margin as needed */
}

.fade-transition {
  transition: opacity 1s ease-in-out;
}

/* styles.css (o cualquier otro nombre que le hayas dado) */

/* Estilos para los botones de navegación */

/*   
} */

.filter-invert {
  filter: saturate(6);
}

.filterHambur {
  filter: saturate(6);
}

img {
  transition: filter 0.2s ease;
}

.shadow-custom {
  --tw-shadow: 0 25px 16px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.text-right-justify {
  text-align: justify;
  text-align-last: right;
}

.shadow-bottom {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Recuadro animado del Home Inicio */
/* Animación para las líneas que aparecen desde la izquierda */
@keyframes line-left {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/* Animación para las líneas que aparecen desde la derecha */
@keyframes line-right {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/* Clases de animación */
.animate-line-left {
  animation: line-left 3s ease-in-out forwards;
}

.animate-line-right {
  animation: line-right 3s ease-in-out forwards;
}

@keyframes line-from-bottom {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

@keyframes line-from-bottom {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

.animate-line-from-bottom {
  animation: line-from-bottom 3s ease-in-out forwards;
}

/* Animación para las líneas verticales que aparecen desde abajo hacia arriba */
@keyframes line-from-bottom {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

/* Clases de animación */
.animate-line-from-bottom {
  animation: line-from-bottom 3s ease-in-out forwards;
}

/* Líneas verticales exteriores */
.vertical-line-top-left {
  height: 100%;
  top: 0;
  left: 0;
  animation: line-from-bottom 3s ease-in-out forwards;
}

.vertical-line-top-right {
  height: 100%;
  top: 0;
  right: 0;
  animation: line-from-bottom 3s ease-in-out forwards;
}

.vertical-line-bottom-left {
  height: 100%;
  bottom: 0;
  left: 0;
  animation: line-from-bottom 3s ease-in-out forwards;
}

.vertical-line-bottom-right {
  height: 100%;
  bottom: 0;
  right: 0;
  animation: line-from-bottom 3s ease-in-out forwards;
}

/* Recuadro animado del Home Fin */

/* Definición de la animación con retraso */
@keyframes appear-border {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Aplicar la animación con retraso */
.animate-border-delay {
  animation: appear-border 3s forwards; /* 3s de retraso */
}

/* aparicion de logo gradual */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-100px); /* Otras transformaciones que desees */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Otras transformaciones que desees */
  }
}

/* Establece una transición para suavizar los cambios de opacidad y transformación */
.slide-transition {
  transition: opacity 1s ease-in-out, transform 0.8s ease-in-out;
}

/* Define la animación de entrada desde el margen izquierdo con opacidad */
.slide-enter {
  opacity: 0; /* Empieza con opacidad 0 */
  transform: translateX(-100%) scale(0.1); /* Cambia el valor de scale según tus necesidades */
}

/* Define la animación de salida hacia la derecha con opacidad */
.slide-exit {
  opacity: 0; /* Termina con opacidad 0 */
  transform: translateX(100%) scale(0.5);
}

.slide-exit-left {
  opacity: 0; /* Termina con opacidad 0 */
  transform: translateX(-100%) scale(0.5);
}

/* Ocultar la barra de desplazamiento vertical */
.carousel-slider {
  overflow-y: hidden;
}

/* Si deseas ocultar la barra de desplazamiento horizontal */
.carousel-slider {
  overflow-x: hidden;
}

/* Clase para establecer la imagen activa */
.slide-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 1.5s ease, opacity 1.5s ease;
}

/* clases para transicion de imagenes */
.class-when-condition-1-next-is-true {
  transform: scale(0.7);
}
.class-when-condition-2-next-is-true {
  transform: translateX(-100%) scale(0.7);
}

.class-when-condition-3-next-is-true {
  opacity: 0;
  transform: translateX(+100%) scale(0.7);
  transition: opacity 0s ease-in-out, transform 0s ease-in-out;
}

.class-when-condition-4-next-is-true {
  transform: translateX(0%) scale(0.7);
  overflow-x: hidden;
}

.class-when-condition-1-prev-is-true {
  transform: scale(0.7);
}
.class-when-condition-2-prev-is-true {
  transform: translateX(+100%) scale(0.7);
}

.class-when-condition-3-prev-is-true {
  opacity: 0;
  transform: translateX(-100%) scale(0.7);
  transition: opacity 1s ease-in-out, transform 0s ease-in-out;
}

.class-when-condition-4-prev-is-true {
  transform: translateX(0%) scale(0.7);
  overflow-x: hidden;
}

/* clases para transicion de titulos */

.slide-transition-tittle {
  transition: opacity 1s ease-in-out, transform 0.4s ease-in-out;
}

.class-when-conditionTitle-1-next-is-true {
  transform: translateY(20%) scale(0.7);
}
.class-when-conditionTitle-2-next-is-true {
  transform: translateX(-200%) translateY(20%) scale(0.7);
  opacity: 0;
}

.class-when-conditionTitle-3-next-is-true {
  opacity: 0;
  transform: translateX(+200%) translateY(20%) scale(0.7);
  transition: opacity 0s ease-in-out, transform 0s ease-in-out;
}

.class-when-conditionTitle-4-next-is-true {
  transform: translateX(0%) translateY(20%) scale(0.7);
  overflow-x: hidden;
}

.class-when-conditionTitle-1-prev-is-true {
  transform: translateY(20%) scale(0.7);
}
.class-when-conditionTitle-2-prev-is-true {
  transform: translateX(+200%) translateY(20%) scale(0.7);
  opacity: 0;
}

.class-when-conditionTitle-3-prev-is-true {
  opacity: 0;
  transform: translateX(-200%) translateY(20%) scale(0.7);
  transition: opacity 0s ease-in-out, transform 0s ease-in-out;
}

.class-when-conditionTitle-4-prev-is-true {
  transform: translateX(0%) translateY(20%) scale(0.7);
  overflow-x: hidden;
}

/* animacion para que aparezcan las letras de a una  */

.letter {
  display: inline-block;
  opacity: 0;
  transform: translateY(20px); /* Desplazamiento hacia abajo */
  transition: opacity 0.5s, transform 0.5s;
}

.letter-show {
  opacity: 1;
  transform: translateY(0); /* Sin desplazamiento */
}

/* mas animacion  */

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-40px) translateY(60px); /* Añade traslación en el eje Y */
  }
  to {
    opacity: 1;
    transform: translateX(0) translateY(0); /* Añade traslación en el eje Y */
  }
}

/* componente family */

.invisible {
  transform: scaleY(0);
  transform-origin: bottom center; /* Establecer el origen en la base inferior */
  transition: opacity 0.3s ease-in, transform 0.3s ease-in;
}

.appear-from-bottom {
  transform: scaleY(1);
  transform-origin: bottom center; /* Establecer el origen en la base inferior */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

/* efecto para dibujar el ciculo del boton del slider */

/*  zoom en la imagen sin afectar su tamaño */
.contenedor {
  overflow: hidden;
  width: 90%;
  position: relative;
}

.imagen {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.5s ease;
}

.recuadro-1,
.recuadro-2 {
  position: absolute;
  border: 1px solid white;
  pointer-events: none;
  z-index: 1;
}

.recuadro-1 {
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
}

.recuadro-2 {
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
}

.contenedor:hover .imagen {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* estilos para popup de edad */

.age-confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.767); /* Fondo semitransparente */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Z-index para que esté por encima del contenido */
}

.age-confirmation-popup-inner {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

/* estilos para el componente de prensa para el efecto de los .awssld__controls__arrow-left */

.digit {
  opacity: 0;
  transform: translateY(1em);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.transition-in {
  opacity: 1;
  transform: translateY(0);
}

.transition-out {
  opacity: 0;
  transform: translateY(-1em);
}

.year-container {
  display: flex;
}
