@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Playfair Regular";
  src: url("../src/fonts/Playfair_Display/static/PlayfairDisplay-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../src/fonts/roboto/Roboto-Regular.ttf") format("woff2");
}

@font-face {
  font-family: "Playfair Black";
  src: url("../src/fonts/Playfair_Display/PlayfairDisplay-Black.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Playfair Italic";
  src: url("../src/fonts/Playfair_Display/PlayfairDisplay-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Playfair Bold";
  src: url("../src/fonts/Playfair_Display/PlayfairDisplay-Bold.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Playfair ExtraBold";
  src: url("../src/fonts/Playfair_Display/PlayfairDisplay-ExtraBold.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Thin";
  src: url("../src/fonts/roboto/Roboto-Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Regular";
  src: url("../src/fonts/roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bondini";
  src: url("../src/fonts/BodoniModa_72pt/BodoniModa_72pt-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "WindsorLigth";
  src: url("../src/fonts/Windsor/windsor-light-bt.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sombra-menu {
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}

/* styles for "Family" start */

.col-image-text-1 {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .col-image-text-1 {
    row-gap: 0.5rem;
  }
}
.col-image-text-1 h1 {
  color: #c4b27d;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.75rem;
  letter-spacing: 0.025em;
  font-family: "Playfair Black";
}

@media (min-width: 768px) {
  .col-image-text-1 h1 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.col-image-text-1 h2 {
  color: #c4b27d;
  letter-spacing: 0.025em;
  font-family: "Roboto";
  font-size: 0.875rem;
  line-height: 1.25rem;
}

@media (min-width: 768px) {
  .col-image-text-1 h2 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.col-image-text-1 p {
  text-align: justify;
  font-family: "Playfair Regular";
  letter-spacing: 0.05em;
  color: black;
  font-size: 0.75rem;
  line-height: 1rem;
  padding-top: 8px;
}

@media (min-width: 1024px) {
  .col-image-text-1 p {
    font-size: 1rem;
    line-height: 1.75rem;
    padding-top: 11px;
  }
}

/* styles for "Family" end */
